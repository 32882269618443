<template>
    <div class="loginRecord-content">


        <div class="loginRecord-content-toolbar">

            <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
                <!--                <h5 style="margin-left: 20px;">类型</h5>-->
                <el-select style="margin-left: 20px;" v-model="userTypeValue" placeholder="选择用户类型"
                           @change="selectRecordChange">
                    <el-option v-for="item in userTypeArrays" :key="item.name" :label="item.name" :value="item.name">
                    </el-option>
                </el-select>
            </div>

            <div style="margin-left: 20px;">
                <el-date-picker v-model="datePickerValue" type="datetimerange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" align="right"
                                @change="datePickerChange">
                </el-date-picker>
            </div>
            <el-button type="primary" style="margin-left: 20px" @click="exportLoginRecord">导出</el-button>
            <el-button type="primary" style="margin-left: 20px" @click="refresh">刷新</el-button>
        </div>
        <div class="loginRecord-content-table">
            <el-table class="userTable" ref="filterTable" :data="userLoginRecordData" height="100%" style="width: 100%;"
                      v-loading="loading">
                <el-table-column
                        label="序号"
                        align="center"
                        type="index"
                        width="100">
                </el-table-column>
                <el-table-column align="center" prop="userName" label="用户名" width="auto">
                </el-table-column>
                <el-table-column align="center" prop="type" label="类型" width="100">
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.type===1 ? "管理员" : "用户" }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="date" label="登录时间" width="auto">
                </el-table-column>
            </el-table>
            <el-pagination style="margin-top: 20px;margin-bottom: 20px;" @size-change="handleSizeChange"
                           @current-change="handleCurrentChange" :current-page="page"
                           :page-sizes="[10, 100, 500, 1000, 2000,3000]"
                           :page-size="length" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>

    </div>
</template>

<script>
    export default {
        name: "loginRecord",
        data() {
            return {
                page: 1,
                length: 10,
                total: 0,
                userLoginRecordData: [],
                loading: false,
                datePickerValue: [localStorage.getItem('userLoginRecordStartDate'), localStorage.getItem('userLoginRecordStopDate')],
                startTime: 0,
                endTime: 0,
                userTypeValue: '用户',
                userTypeArrays: [{
                    name: '管理员'
                }, {
                    name: '用户'
                }]
            }
        },
        mounted() {
            this.refresh()
        },
        methods: {
            selectRecordChange(value) {
                this.userTypeValue = value
                this.refresh()
            },
            datePickerChange(datePickerValue) {
                localStorage.setItem('userLoginRecordStartDate', datePickerValue[0])
                localStorage.setItem('userLoginRecordStopDate', datePickerValue[1])
                this.refresh()
            },
            refresh() {
                this.pagingQuery()
            },
            handleSizeChange(length) {
                this.length = length
                this.pagingQuery()
            },
            handleCurrentChange(page) {
                this.page = page
                this.pagingQuery()
            },
            handleClose(done) {
                done()
            },
            pagingQuery() {
                this.loading = true
                var startTime = new Date(this.datePickerValue[0]).getTime()
                var endTime = new Date(this.datePickerValue[1]).getTime()
                setTimeout(() => {
                    this.loading = true
                    axios.post('userLoginRecord/pagingQuery', {
                        page: this.page,
                        length: this.length,
                        startTime: startTime,
                        endTime: endTime,
                        parameter: {type: this.userTypeValue === '管理员' ? 1 : 0}
                    }).then((res) => {
                        this.loading = false
                        if (res.data.code === 1) {
                            this.userLoginRecordData = res.data.data.content
                            this.total = res.data.data.totalElements
                        } else {
                            this.userData = []
                            this.total = 0
                        }
                    }).catch((error) => {
                        this.loading = false
                    })
                })
            },
            exportLoginRecord() {
                var startTime = new Date(this.datePickerValue[0]).getTime()
                var endTime = new Date(this.datePickerValue[1]).getTime()
                setTimeout(() => {
                    axios.post('/userLoginRecord/download', {
                        page: this.page,
                        length: this.length,
                        startTime: startTime,
                        endTime: endTime,
                        parameter: {type: this.userTypeValue === '管理员' ? 1 : 0}

                    }, {
                        responseType: 'blob'
                    }).then((res) => {
                        let data = res.data
                        const link = document.createElement('a')
                        let blob = new Blob([data], {
                            type: 'application/vnd.ms-excel'
                        })
                        link.style.display = 'none'
                        link.href = URL.createObjectURL(blob)
                        link.download = '用户登录记录.xls'
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                    })
                })
            }
        }
    }
</script>

<style scoped>
    .loginRecord-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

    }

    .loginRecord-content-toolbar {
        display: flex;
        margin-top: 20px;
        flex-direction: row;
        align-items: center;
        width: 95%;
        height: 100px;
        min-height: 100px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
        overflow-x: scroll;
    }

    .loginRecord-content-table {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 95%;
        height: 100%;
        margin-bottom: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
    }
</style>